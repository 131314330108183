import { useRouter } from 'next/router'
import { useLoggedIn } from '@netpurpose/core'
import { PageNotFound } from '@netpurpose/np-ui'
import { ShowIfAuthorised } from '#components/ShowIfAuthorised'

const Protected404 = () => {
  const router = useRouter()
  const user = useLoggedIn()

  return (
    <ShowIfAuthorised isAuthorised={user && true} title="Page not found">
      <PageNotFound backAction={() => router.back()} />
    </ShowIfAuthorised>
  )
}

export default Protected404
